<template>
	<div class="print-list">
		<h4 class="no-print mb-4">{{ sysLabelGetter('print_list') }}:</h4>

		<div v-if="printList.length" class="print-list__actions justify-content-sm-end justify-content-start mb-4">
			<b-button @click="printAction()" :class="`no-print btn ${brandVariant}`">
				{{sysLabelGetter('print')}}
			</b-button>
			<b-button @click="clearPrintListMt()" variant="danger" class="no-print">
				{{ sysLabelGetter('clear_print_list') }}
			</b-button>
		</div>

		<OrderInfo
			v-for="(item, index) of partsList"
			:key="index"
			:data="item"
			:identifier="`idx-${index}__part-${item.id}`"
		/>

		<div v-if="printList.length > 5" class="print-list__actions justify-content-sm-end justify-content-start mt-4 mb-5">
			<b-button @click="printAction()" :class="`no-print btn ${brandVariant}`">
				{{sysLabelGetter('print')}}
			</b-button>
			<b-button @click="clearPrintListMt()" variant="danger" class="no-print">
				{{ sysLabelGetter('clear_print_list') }}
			</b-button>
		</div>

		<LabelList :list="currentProductList" v-if="Object.keys(currentProductList).length" class="mt-5 no-print"/>
	</div>
</template>

<script>
import OrderInfo from '@/components/OrderInfo.vue';
import LabelList from '@/components/LabelList.vue';

import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
	name: 'PrintList',
	components: {
		OrderInfo,
		LabelList,
	},
	computed: {
		...mapState( 'main', {
			printList: 'printList',
			products: 'products',
			parts: 'parts'
		} ),
        ...mapGetters("main", {
            labelGetter:'labelGetter',
            sysLabelGetter:'sysLabelGetter',
            brandVariant: "brandVariant",
        }),
		partsList() {
			let output = [];
			this.printList.forEach( item => {
				let data = JSON.parse(JSON.stringify(this.parts[item]));
				output.push({
					id: item,
					...data,
					footnote: data.footnote ? this.labelGetter(data.footnote, `footnote of part ${item}`) : '',
					label: data.label ? this.labelGetter(data.label, `part ${item}`) : ''
				})
			} )
			return output;
		},
		currentProductList() {
			let output = {};
			let productIds = [];
			this.partsList.forEach( item => {
				productIds = Array.from( new Set( productIds.concat(item.products) ) );
			} )
			productIds.forEach( id => {
				let product = this.products[id];
				output[id] = {
					_id: product._id,
					label: this.labelGetter(product.label, `product ${product._id}`),
					link: { name: 'product', params: { id } }
				};
			} )
			return output;
		},
	},
	methods: {
		...mapMutations( 'main', {
			clearPrintList: 'clearPrintList',
		} ),
		printAction() {
			if ( this.printList.length ) window.print();
			console.log('printAction');
		},
		clearPrintListMt() {
			this.clearPrintList();
			this.$localStorage.removeItem('print-list');
		},
	},
}
</script>

<style lang="scss" scoped>

.print-list {
	&__actions {
		display: flex;
		gap: 1.5rem;
	}
}

@media print {
	.no-print {
		display: none;
	}
}

</style>